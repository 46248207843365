import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { RichText } from "prismic-reactjs"

import styled from "styled-components"
import Swiper from "../components/swiper"
const Content = styled.div`
  max-width: 1200px;
  margin: 3rem auto 4rem auto;
  display: flex;
  flex-direction: row-reverse;
  scroll-behavior: smooth;
  p {
    word-break: normal;
    hyphens: auto;
    margin-bottom: 1.45em;
    min-height: 1em;
  }
  @media only screen and (max-width: 1080px) {
    flex-direction: column;
  }
`

const Text = styled.div`
  padding: 0 3rem;
  position: relative;
  width: 100%;
  margin-left: auto;
  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
`

class Banner extends React.Component {
  render() {
    const data = this.props.data.prismic.allBannerpages.edges[0].node

    return (
      <Layout location={this.props.location.pathname}>
        <Content id="top">
          <Text id="kopf">{RichText.render(data.body)}</Text>
        </Content>
        <Swiper slider={data.slider} />
      </Layout>
    )
  }
}

export default Banner

export const pageQuery = graphql`
  query bannerqueryEn {
    prismic {
      allBannerpages(lang: "en-gb") {
        edges {
          node {
            body
            slider {
              slidebody
              image
            }
          }
        }
      }
    }
  }
`
